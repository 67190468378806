import {createGlobalStyle} from 'styled-components';
import {reset} from '@brightlive/shared/styles/reset';
import {theme} from '@brightlive/shared/styles/theme';

const GlobalStyles = createGlobalStyle`

${reset}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// osano styles

.osano-cm-window {
  font-family: ${theme.typeface.owners};
}

.osano-cm-button {
  font-family: ${theme.typeface.owners};
  border: none;
  height: 40px;
  border-radius: 100px;
  font-weight: 300;
  font-size: 16px;
}

.osano-cm-deny {
  border: ${theme.borderWidth[1]} solid ${theme.color.white};
}

.osano-cm-link {
  font-weight: 700;
}

.osano-cm-dialog--type_box .osano-cm-dialog__buttons {
  margin: 1em 0 0;
}

.osano-cm-widget {
  display: none;
}

// lazyload styles

.lazyload,
.lazyloading {
	opacity: 0;
}
.lazyloaded {
	opacity: 1;
	transition: opacity 300ms;
}
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: ${theme.contentColor.accent};

  position: fixed;
  z-index: 2001;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px ${theme.color.neon500}, 0 0 5px ${theme.color.neon500};
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 2001;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: ${theme.color.neon500};
  border-left-color: ${theme.color.neon500};
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

`;

export default GlobalStyles;
