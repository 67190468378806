import keyMirror from 'keymirror';

const types = keyMirror({
  TOGGLE_NAV: null,
  SET_LOGIN_LOADING: null,
  SET_LOGIN_CLOSABLE: null,
  TOGGLE_SESSION_THEME: null,
  TOGGLE_LOGIN_MODAL: null,
  TOGGLE_SETTINGS_MODAL: null,
  TOGGLE_FEATURES_LOCK_MODAL: null,
  SET_LOGIN_MODAL_PAGE: null,
  SET_LOGIN_MODAL_MESSAGE: null,
  SET_LOGIN_MODAL_EMAIL: null,
  GET_SIGNUP_CONTENT: null,
  TOGGLE_PRIVATE_SESSION_MODAL: null,
  TOGGLE_TOAST: null,
  TOGGLE_MESSAGE_CALLOUT: null,
  TOGGLE_ALERT_BANNER: null,
});

export default types;
