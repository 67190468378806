import {combineReducers} from 'redux';
import {AnyAction} from 'redux';
import types from './types';
import {AuthPages} from '@brightlive/shared/helpers/interfaces';
import {IconNames} from '@brightlive/shared/icons';
import {assign} from 'lodash';

const navVisible = (state = true, action: AnyAction) => {
  switch (action.type) {
    case `${types.TOGGLE_NAV}`:
      return action.toggled;
    default:
      return state;
  }
};
const loginLoading = (state = true, action: AnyAction) => {
  switch (action.type) {
    case `${types.SET_LOGIN_LOADING}`:
      return action.toggled;
    default:
      return state;
  }
};
const loginClosable = (state = true, action: AnyAction) => {
  switch (action.type) {
    case `${types.SET_LOGIN_CLOSABLE}`:
      return action.toggled;
    default:
      return state;
  }
};
const settingsModalVisible = (state = false, action: AnyAction) => {
  switch (action.type) {
    case `${types.TOGGLE_SETTINGS_MODAL}`:
      return action.toggled;
    default:
      return state;
  }
};
const loginModalVisible = (state = false, action: AnyAction) => {
  switch (action.type) {
    case `${types.TOGGLE_LOGIN_MODAL}`:
      return action.toggled;
    default:
      return state;
  }
};
const featureLockModalVisible = (state = false, action: AnyAction) => {
  switch (action.type) {
    case `${types.TOGGLE_FEATURES_LOCK_MODAL}`:
      return action.toggled;
    default:
      return state;
  }
};

const loginModalPage = (state = 'login', action: AnyAction) => {
  switch (action.type) {
    case `${types.SET_LOGIN_MODAL_PAGE}`:
      return action.page;
    default:
      return state;
  }
};

const loginModalMessage = (state = '', action: AnyAction) => {
  switch (action.type) {
    case `${types.SET_LOGIN_MODAL_MESSAGE}`:
      return action.page;
    default:
      return state;
  }
};

const loginModalEmail = (state = '', action: AnyAction) => {
  switch (action.type) {
    case `${types.SET_LOGIN_MODAL_EMAIL}`:
      return action.email;
    default:
      return state;
  }
};

const sessionTheme = (state = 'light', action: AnyAction) => {
  switch (action.type) {
    case `${types.TOGGLE_SESSION_THEME}`:
      return action.theme;
    default:
      return state;
  }
};

interface ToastInitState {
  toggled: boolean;
  text: string;
  toastType: 'default' | 'negative';
  icon?: IconNames;
  placement: 'top' | 'bottom';
}

const toastInitState: ToastInitState = {
  toggled: false,
  text: '',
  toastType: 'default',
  icon: undefined,
  placement: 'bottom',
};

const toast = (state = toastInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.TOGGLE_TOAST}`:
      return {
        toggled: action.toggled,
        text: action.text,
        icon: action.icon,
        toastType: action.toastType,
        placement: action.placement,
      };
    default:
      return state;
  }
};
interface MessageCalloutInitState {
  toggled: boolean;
  text: string;
  name: string;
  messageType: 'chat' | 'question' | '';
}

const messageCalloutInitState: MessageCalloutInitState = {
  toggled: false,
  text: '',
  name: '',
  messageType: '',
};

const messageCallout = (state = messageCalloutInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.TOGGLE_MESSAGE_CALLOUT}`:
      return {
        toggled: action.toggled,
        text: action.text,
        name: action.name,
        messageType: action.messageType,
      };
    default:
      return state;
  }
};

interface AlertBannerInitState {
  persist: boolean;
  text: string;
  alertType: 'warning' | 'alert';
}

const alertBannerInitState: AlertBannerInitState = {
  text: '',
  alertType: 'warning',
  persist: false,
};

const alertBanner = (state = alertBannerInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.TOGGLE_ALERT_BANNER}`:
      return {
        text: action.text,
        alertType: action.alertType,
        persist: action.persist,
      };
    default:
      return state;
  }
};

interface SignupInitState {
  title: string;
  subtitle: string;
  points: string[];
}

const signupInitState: SignupInitState = {
  title: 'What show will you create today?',
  subtitle:
    'Bright makes it easy to record professional video podcasts, interviews and live streams',
  points: [
    'Quality recordings without the need of expensive editing tools',
    'Live stream to multiple social platforms',
    'Switch stage layouts on the fly',
    'Invite up to 150 fully interactive guests',
    'Record on the go from any browser and any device',
  ],
};

const signup = (state = signupInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_SIGNUP_CONTENT}_SUCCESS`:
      return assign({}, state, {
        ...action?.data,
      });
    default:
      return state;
  }
};

interface PrivateSessionModalInitState {
  toggled: boolean;
  creatorDisplayName: string;
}

const privateSessionModalInitState: PrivateSessionModalInitState = {
  toggled: false,
  creatorDisplayName: '',
};

const privateSessionModal = (
  state = privateSessionModalInitState,
  action: AnyAction
) => {
  switch (action.type) {
    case `${types.TOGGLE_PRIVATE_SESSION_MODAL}`:
      return {
        toggled: action.toggled,
        creatorDisplayName: action.creatorDisplayName,
      };
    default:
      return state;
  }
};

// UI state
interface UIState {
  navVisible: boolean;
  loginLoading: boolean;
  loginClosable: boolean;
  sessionTheme: string;
  settingsModalVisible: boolean;
  featureLockModalVisible: boolean;
  loginModalVisible: boolean;
  loginModalPage: AuthPages;
  loginModalEmail: string;
  loginModalMessage: string;
  privateSessionModal: PrivateSessionModalInitState;
  signup: SignupInitState;
  toast: ToastInitState;
  messageCallout: MessageCalloutInitState;
  alertBanner: AlertBannerInitState;
}
// Used for redux selector hooks
export interface UIReducer {
  ui: UIState;
}

export default combineReducers({
  navVisible,
  loginLoading,
  loginClosable,
  sessionTheme,
  settingsModalVisible,
  featureLockModalVisible,
  loginModalVisible,
  loginModalMessage,
  loginModalPage,
  loginModalEmail,
  signup,
  privateSessionModal,
  toast,
  messageCallout,
  alertBanner,
});
