import {combineReducers, AnyAction} from 'redux';
import {assign} from 'lodash';
import types from './types';
import userTypes from 'redux/user/types';
// Helpers
import {User} from '@brightlive/shared/helpers/interfaces';
import {
  SubscriptionSpan,
  SubscriptionStatus,
  SubscriptionTier,
} from '@brightlive/shared/helpers/enums';

interface AuthInitState {
  continueUrl: string | null;
  authToken: string | null;
  error: unknown;
}

const initAuthState: AuthInitState = {
  continueUrl: null,
  authToken: null,
  error: null,
};

const auth = (state = initAuthState, action: AnyAction) => {
  switch (action.type) {
    case `${types.SET_CONTINUE_URL}`: {
      return {
        ...state,
        continueUrl: action.url,
      };
    }
    case `${types.SET_AUTH_TOKEN}`: {
      return {
        ...state,
        authToken: action.authToken,
      };
    }
    case `${types.LOGOUT}_SUCCESS`:
      return initAuthState;
    default:
      return state;
  }
};

export type UserInitState = User & {
  success: unknown;
  updated: boolean;
  deleted: boolean;
  error: Record<string, unknown>;
};

const userInitState: UserInitState = {
  email: '',
  firstName: '',
  lastName: '',
  displayName: '',
  phone: '',
  phoneVerified: false,
  avatar: '',
  agreedToPrivacy: null,
  agreedToTerms: null,
  newPrivacyAvail: false,
  newTermsAvail: false,
  favorites: [],
  uid: '',
  id: '',
  slug: null,
  emailVerified: false,
  agreedToWatchDisclosure: false,
  roles: ['supporter'],
  passwordUpdatedAt: '',
  hasWatchAccess: false,
  managerFor: [],
  subscription: {
    id: '',
    invoiceID: '',
    status: SubscriptionStatus.InTrial,
    plan: SubscriptionTier.Free,
    billed: SubscriptionSpan.Yearly,
    futurePlan: null,
    hasScheduledChanges: false,
  },
  success: null,
  updated: false,
  deleted: false,
  error: {},
};

const currentUser = (state = userInitState, action: AnyAction) => {
  switch (action.type) {
    case `${types.GET_CURRENT_USER}_REQUEST`:
    case `${types.UPDATE_USER}_REQUEST`:
    case `${userTypes.CONNECT_TO_CREATOR}_REQUEST`:
    case `${userTypes.DISCONNECT_FROM_CREATOR}_REQUEST`:
    case `${types.RESET_CURRENT_USER_STATE}`:
      return assign({}, state, {
        success: false,
        updated: false,
        error: false,
        deleted: false,
      });
    case `${types.GET_CURRENT_USER}_SUCCESS`:
    case `${types.UPDATE_CURRENT_USER}`:
    case `${userTypes.SIGN_UP_USER}_SUCCESS`:
      return assign(
        {},
        {
          ...action?.data,
          error: null,
          success: true,
          updated: false,
          deleted: false,
        }
      );
    case `${userTypes.CONNECT_TO_CREATOR}_SUCCESS`:
    case `${userTypes.DISCONNECT_FROM_CREATOR}_SUCCESS`:
      return assign({}, state, {
        favorites: action?.data,
        error: null,
        success: true,
        updated: false,
        deleted: false,
      });
    case `${types.UPDATE_USER}_SUCCESS`:
      return assign(
        {},
        {
          ...action?.data,
          error: null,
          success: true,
          updated: true,
          deleted: false,
        }
      );
    case `${types.LOGOUT}_SUCCESS`:
      return userInitState;
    case `${types.GET_CURRENT_USER}_FAILURE`:
    case `${types.UPDATE_USER}_FAILURE`:
    case `${userTypes.DISCONNECT_FROM_CREATOR}_FAILURE`:
    case `${userTypes.CONNECT_TO_CREATOR}_FAILURE`:
    case `${userTypes.SIGN_UP_USER}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error,
        updated: false,
        deleted: false,
      });
    default:
      return state;
  }
};
// Auth state
export interface AuthState {
  currentUser: UserInitState;
  auth: AuthInitState;
}
// Used for redux selector hooks
export interface AuthReducer {
  auth: AuthState;
}

export default combineReducers({
  auth,
  currentUser,
});
