export enum SubscriptionTier {
  Base = '_base',
  Unlimited = 'unlimited',
  Free = 'free',
  Standard = 'standard',
  Pro = 'pro',
}

export enum SubscriptionStatus {
  Future = 'future', // The subscription is scheduled to start at a future date.
  InTrial = 'in_trial', // The subscription is in trial.
  Active = 'active', // The subscription is active and will be charged for automatically based on the items in it.
  NonRenewing = 'non_renewing', // The subscription will be canceled at the end of the current term.
  Paused = 'paused', // The subscription is paused. The subscription will not renew while in this state.
  Cancelled = 'cancelled', // The subscription has been canceled and is no longer in service.
}

export enum SubscriptionSpan {
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export enum Banners {
  clear = '',
  joinedStage = 'Welcome to the stage!',
  unstableInternet = 'Your internet connection is unstable',
  AskToLeaveStage = 'Ready to leave the stage?',
}
export enum Toasts {
  joinedStage = ' joined the stage',
  leftStage = ' left the stage',
  declinedInvite = ' wants to remain off stage',
  stageIsFull = ' cannot join the stage, the stage is full',
}

export const enum AnalyticsEvent {
  LESSON_BROWSE = 'lesson_browse',
  LESSON = 'lesson',
  SEARCH = 'search',
}

export enum SessionSectionTypes {
  currentWeek = 'Happening this week',
  upcoming = 'Upcoming Sessions',
  past = 'Past Sessions',
}

export const enum BranchEvent {
  INITIATE_PURCHASE = 'INITIATE_PURCHASE',
  PURCHASE = 'PURCHASE',
  LOGIN = 'LOGIN',
  COMPLETE_REGISTRATION = 'COMPLETE_REGISTRATION',
}
